export const Tomorrow = ({ className }: { className?: string }) => (
    <svg
        className={`Tomorrow ${className}`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.7094 6.66976C12.8705 5.7657 11.7735 5.18452 10.5474 5.05537C9.38581 4.86164 8.15972 5.18452 7.12723 5.89485C6.09473 6.60518 5.38489 7.63839 5.12677 8.86532C4.86864 10.0277 4.9977 11.3192 5.57848 12.3524C6.15926 13.4502 7.12723 14.2897 8.28878 14.6771C8.86956 14.8708 9.45034 15 9.96659 15C10.6119 15 11.1927 14.8708 11.838 14.6771C12.9995 14.2251 13.903 13.3856 14.4838 12.2233L14.5483 12.1587C14.5483 12.0941 14.6128 12.0295 14.6128 11.9649C14.8709 11.3192 15 10.6734 15 10.0277C15 8.73617 14.5483 7.57381 13.7094 6.66976ZM14.032 9.96311C14.032 10.4797 13.903 11.0609 13.7094 11.5129L13.5803 11.7712C13.1286 12.6753 12.3542 13.321 11.4508 13.7085C10.5474 14.0959 9.51487 14.0959 8.61144 13.7731C7.708 13.4502 6.93363 12.7399 6.41738 11.9004C5.96567 11.0609 5.83661 10.0277 6.09473 9.05905C6.28832 8.09042 6.8691 7.25094 7.708 6.66976C8.41784 6.21773 9.19222 5.95943 10.0311 5.95943C10.1602 5.95943 10.3538 5.95943 10.4828 5.95943C11.4508 6.08858 12.3542 6.54061 12.9995 7.31551C13.6448 8.02584 14.032 8.99448 14.032 9.96311Z"
            fill="var(--svg-gray1-gray3)"
        />
        <path
            d="M10.4836 9.76913V7.33679C10.4836 7.07849 10.29 6.88477 9.96734 6.88477C9.70922 6.88477 9.51562 7.07849 9.51562 7.33679V9.96286C9.51562 10.092 9.58016 10.2212 9.64469 10.2857L11.258 11.9001C11.3225 11.9647 11.4516 12.0293 11.5806 12.0293C11.7097 12.0293 11.8387 11.9647 11.9033 11.9001C12.0969 11.7064 12.0969 11.3835 11.9033 11.1898L10.4836 9.76913Z"
            fill="var(--svg-gray1-gray3)"
        />
    </svg>
);

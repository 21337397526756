import { AnalyticsInstance } from '@/common/service/analytics';
import { trackViewItemList } from '.';
import { IItem } from './interfaces';

type ItemGA3 = {
    list: string;
    category: string;
    position: number;
    name: string;
    id: string;
    price: number;
    brand: string;
};

class ImpressionInterceptor {
    _impressionArray: ItemGA3[] = [];
    _impressionArrayGA4: IItem[] = [];
    _viewed: number[] = [];
    _category = '';
    _updatedStarted = false;
    /* eslint-disable sonarjs/cognitive-complexity */
    addImpression(item: ItemGA3, isList: boolean, activity: IItem) {
        if (isList && this._category !== item.category) {
            this._category = item.category;
            this._viewed = [];
        }

        if (!this._viewed.includes(item.position) || !isList) {
            if (isList) {
                this._viewed.push(item.position);
            }
            this._impressionArray.push(item);
            if (activity) {
                this._impressionArrayGA4.push(activity);
            }
        }

        if (!this._updatedStarted) {
            this._updatedStarted = true;

            setTimeout(() => {
                /* split for list types */
                if (this._impressionArray.every((v) => v.list === this._impressionArray[0].list)) {
                    /* if list types similar just track in one object */
                    AnalyticsInstance.impression.dispatch(this._impressionArray);
                    trackViewItemList({
                        items: this._impressionArrayGA4,
                    });
                } else {
                    /* if list types is different */
                    const sortList: { [key: string]: ItemGA3[] } = {};
                    const sortListGA4: { [key: string]: IItem[] } = {};
                    /* create object with types { 'type name': [value_1, value_2, ...]} */
                    this._impressionArray.forEach((v, i) => {
                        if (sortList[v.list]) {
                            sortList[v.list].push(v);
                            sortListGA4[v.list].push(this._impressionArrayGA4[i]);
                        } else {
                            sortList[v.list] = [v];
                            sortListGA4[v.list] = [this._impressionArrayGA4[i]];
                        }
                    });
                    /* iteration for object to send different types in different events */
                    for (const [, value] of Object.entries(sortList)) {
                        AnalyticsInstance.impression.dispatch(value);
                    }
                    for (const [, value] of Object.entries(sortListGA4)) {
                        trackViewItemList({
                            items: value,
                        });
                    }
                }
                /* setup default values */
                this._impressionArray = [];
                this._impressionArrayGA4 = [];
                this._updatedStarted = false;
            }, 1500);
        }
    }
}

export const impressionInterceptor = new ImpressionInterceptor();
